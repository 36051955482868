<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLayout } from '../composables/layout'
import { useUser } from '../composables/user'
import { AppRoute } from '../router'
import AppBtn from './AppBtn.vue'
import AppCard from './AppCard.vue'
import AppCardActions from './AppCardActions.vue'
import AppCardText from './AppCardText.vue'
import AppCardTitle from './AppCardTitle.vue'
import AppContainer from './AppContainer.vue'
import AppDeliveryMessagesBanner from './AppDeliveryMessagesBanner.vue'
import AppDialog from './AppDialog.vue'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader.vue'
import AppSidebar from './AppSidebar.vue'
import AppSubscribeBanner from './AppSubscribeBanner.vue'

const props = defineProps<{
  currentRoute?: AppRoute
  logoutDialog?: boolean
  logoutLoading?: boolean
}>()

const emit = defineEmits<{
  (event: 'logout'): void
  (event: 'update:logoutDialog', value: boolean): void
}>()

const { t } = useI18n()
const { headerHeight, sidebarVisible } = useLayout()
const { user } = useUser()

const hasSubscription = computed(() => {
  if (user.value?.subscription)
    return true

  return false
})

const isPartner = computed(() => {
  if (user.value?.isPartner)
    return true

  return false
})

const isWhitelisted = computed(() => {
  if (user.value?.isWhitelisted)
    return true

  return false
})

const showSubscribeBanner = computed(() => {
  if (props.currentRoute === AppRoute.Billing)
    return false

  if (isPartner.value)
    return false

  if (isWhitelisted.value)
    return false

  return !hasSubscription.value
})

const disabledDeliveryMessages = computed(() => {
  return user.value?.disabledDeliveryMessages === true
})

const showDeliveryMessagesBanner = computed(() => {
  if (props.currentRoute === AppRoute.DeliveryMessages)
    return false

  return !disabledDeliveryMessages.value
})
</script>

<template>
  <div
    class="relative min-h-screen flex flex-auto flex-col"
    :style="{ paddingTop: `${headerHeight}px` }"
  >
    <AppHeader @logout="emit('update:logoutDialog', true)" />
    <AppSidebar
      v-if="sidebarVisible"
      :current-route="props.currentRoute"
    />
    <div class="flex flex-auto flex-col">
      <AppContainer v-if="showSubscribeBanner || showDeliveryMessagesBanner" class="mt-5">
        <div class="space-y-1.5">
          <AppSubscribeBanner v-if="showSubscribeBanner" />
          <AppDeliveryMessagesBanner v-if="showDeliveryMessagesBanner" />
        </div>
      </AppContainer>
      <AppContainer class="py-8" :flex-auto="true">
        <slot />
      </AppContainer>
      <AppContainer>
        <AppFooter class="pb-2 pt-8" />
      </AppContainer>
    </div>
  </div>

  <AppDialog
    :model-value="props.logoutDialog"
    :persistent="props.logoutLoading"
    @update:model-value="value => emit('update:logoutDialog', value)"
  >
    <AppCard class="w-sm">
      <AppCardTitle class="text-xl">
        {{ t('logout-dialog-title') }}
      </AppCardTitle>
      <AppCardText class="text-lg !pt-1">
        {{ t('logout-dialog-info', { name: user?.shopDomain }) }}
      </AppCardText>
      <AppCardActions class="justify-end">
        <AppBtn
          :disabled="props.logoutLoading"
          @click="emit('update:logoutDialog', false)"
        >
          {{ t('cancel') }}
        </AppBtn>
        <AppBtn
          color="red"
          :loading="props.logoutLoading"
          @click="emit('logout')"
        >
          {{ t('logout') }}
        </AppBtn>
      </AppCardActions>
    </AppCard>
  </AppDialog>
</template>
