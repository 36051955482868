import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

export function initSentry(options: {
  app: App
  router: Router
  dsn: string
  environment?: string
  release?: string
  tracesSampleRate?: number
}) {
  Sentry.init({
    app: options.app,
    dsn: options.dsn,
    environment: options.environment,
    release: options.release,
    integrations: [
      Sentry.browserTracingIntegration({ router: options.router }),
    ],
    tracePropagationTargets: ['localhost', /^\//],
    tracesSampleRate: options.tracesSampleRate,
  })
}
