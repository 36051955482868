<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

const visible = ref(false)
const container = ref()
onClickOutside(container, () => visible.value = false)
</script>

<template>
  <div ref="container" class="relative" @click="visible = !visible">
    <slot name="trigger" />
    <transition
      enter-active-class="animated animate-fade-in animate-duration-200"
      leave-active-class="animated animate-fade-out animate-duration-200"
    >
      <div v-if="visible" class="absolute right-0 origin-top-right border rounded-lg bg-white px-2 py-2.5 shadow-lg">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>
