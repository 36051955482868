import type { RouteLocationRaw } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import { toRaw } from 'vue'
import { useRouter } from 'vue-router'

export function deepClone<T>(value: T): T {
  return structuredClone(toRaw(value))
}

export function deepEquals(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function resolveRouteHref(to: RouteLocationRaw): string {
  const router = useRouter()
  try {
    return router.resolve(to).href
  }
  catch {
    return ''
  }
}

export function sortByKey(array: any[], key: string, order: 'asc' | 'desc' = 'asc') {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    if (x < y)
      return order === 'asc' ? -1 : 1
    if (x > y)
      return order === 'asc' ? 1 : -1
    return 0
  })
}

export function scrollIntoView(element: Element, offset = 0) {
  window.scrollTo({
    top: element.getBoundingClientRect().top
      - document.body.getBoundingClientRect().top
      - offset,
  })
}

export function leadingZero(value: number, length = 2): string {
  return value.toString().padStart(length, '0')
}

export function uniqueId(): string {
  return uuidv4()
}

export function getCookie(name: string): string {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=')
    if (key.trim() === name)
      return value
  }
  return ''
}
