<script setup lang="ts">
import { computed, ref } from 'vue'
import AppIcon from './AppIcon.vue'
import AppLoadingSpinner from './AppLoadingSpinner.vue'

const props = withDefaults(defineProps<{
  color?: 'primary' | 'green' | 'red' | 'gray'
  size?: 'normal' | 'lg' | 'sm'
  outlined?: boolean
  block?: boolean
  iconBefore?: string
  iconAfter?: string
  loading?: boolean
  disabled?: boolean
  href?: string
  roundedFull?: boolean
}>(), {
  color: 'gray',
  size: 'normal',
})

const emit = defineEmits<{
  (event: 'click', value: MouseEvent): void
}>()

const button = ref<HTMLButtonElement | HTMLAnchorElement>()

function clickHandler(e: MouseEvent) {
  if (props.loading || props.disabled)
    return

  emit('click', e)
  button.value?.blur()
}

const iconSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return '18px'
    case 'lg':
      return '26px'
    default:
      return '22px'
  }
})
</script>

<template>
  <button
    ref="button"
    class="group relative inline-flex cursor-pointer select-none items-center whitespace-nowrap font-semibold leading-none ring-1"
    :class="{
      'rounded': !props.roundedFull,
      'rounded-full': props.roundedFull,

      'h-30px px-4 text-sm': props.size === 'sm',
      'h-36px px-4.5': props.size === 'normal',
      'h-50px px-5 text-lg': props.size === 'lg',

      '!pointer-events-none': props.loading,
      '!pointer-events-none !opacity-40': props.disabled,
      '!flex w-full': props.block,

      'shadow': !props.outlined,
      'bg-opacity-0 hover:bg-opacity-5 !active:bg-opacity-10 dark:ring-opacity-50': props.outlined,

      'bg-primary-600 hover:bg-primary-700 !active:bg-primary-800 ring-primary-600': props.color === 'primary',
      'text-primary-50 hover:text-white hover:ring-primary-800 active:ring-primary-800': props.color === 'primary' && !props.outlined,
      'text-primary-600 hover:text-primary-700 !active:text-primary-800': props.color === 'primary' && props.outlined,

      'bg-green-600 hover:bg-green-700 !active:bg-green-800 ring-green-600': props.color === 'green',
      'text-green-50 hover:text-white hover:ring-green-800 active:ring-green-800': props.color === 'green' && !props.outlined,
      'text-green-600 hover:text-green-700 !active:text-green-800': props.color === 'green' && props.outlined,

      'bg-red-600 hover:bg-red-700 !active:bg-red-800 ring-red-600': props.color === 'red',
      'text-red-50 hover:text-white hover:ring-red-800 active:ring-red-800': props.color === 'red' && !props.outlined,
      'text-red-600 hover:text-red-700 !active:text-red-800': props.color === 'red' && props.outlined,

      'bg-gray-50 ring-gray-300 hover:bg-gray-100 active:bg-gray-200 active:bg-opacity-60 text-gray-600 hover:text-gray-700 active:text-gray-800': props.color === 'gray',
      'dark:text-gray-400 dark:hover:text-white dark:active:text-white light:hover:ring-gray-400 light:active:ring-gray-400 light:hover:bg-opacity-50 !light:active:bg-opacity-50': props.color === 'gray' && props.outlined,
    }"
    :disabled="props.disabled || props.loading"
    @click.prevent="clickHandler"
  >
    <span v-if="props.loading" class="absolute inset-0 flex items-center justify-center">
      <AppLoadingSpinner size="24px" />
    </span>
    <span
      class="relative w-full flex items-center justify-center"
      :class="{
        'opacity-30': props.loading,

        'pl-7': props.iconBefore && props.size === 'sm',
        'pl-9': props.iconBefore && props.size === 'normal',
        'pl-10': props.iconBefore && props.size === 'lg',

        'pr-7': props.iconAfter && props.size === 'sm',
        'pr-9': props.iconAfter && props.size === 'normal',
        'pr-10': props.iconAfter && props.size === 'lg',
      }"
    >
      <AppIcon
        v-if="props.iconBefore"
        :icon="props.iconBefore"
        class="absolute left-0 opacity-80 group-hover:group-active:opacity-100"
        :size="iconSize"
      />
      <slot />
      <AppIcon
        v-if="props.iconAfter"
        :icon="props.iconAfter"
        class="absolute right-0 opacity-80 group-hover:group-active:opacity-100"
        :size="iconSize"
      />
    </span>
  </button>
</template>
