import type { Router, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

interface ExtendedRouteMeta {
  layout?: 'default' | 'blank'
}

type ExtendedRouteRecordRaw = RouteRecordRaw & {
  meta?: ExtendedRouteMeta
}

declare module 'vue-router' {
  interface RouteMeta extends ExtendedRouteMeta { }
}

export enum AppRoute {
  Billing = 'Billing',
  DeliveryAreas = 'DeliveryAreas',
  DeliveryAreasCreate = 'DeliveryAreas.Create',
  DeliveryAreasEdit = 'DeliveryAreas.Edit',
  DeliveryMessages = 'DeliveryMessages',
  Widgets = 'Widgets',
  WidgetsCreate = 'Widgets.Create',
  WidgetsEdit = 'Widgets.Edit',
  GeneralSettings = 'GeneralSettings',
  Holidays = 'Holidays',
  Home = 'Home',
  Login = 'Login',
  PageNotFound = 'PageNotFound',
  Products = 'Products',
  ProductsEdit = 'Products.Edit',
}

const routes: ExtendedRouteRecordRaw[] = [
  {
    path: '/',
    name: AppRoute.Home,
    component: () => import('@delm/merchant-app/pages/Home/Route.vue'),
  },
  {
    path: '/login',
    name: AppRoute.Login,
    component: () => import('@delm/merchant-app/pages/Login/Route.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/billing',
    name: AppRoute.Billing,
    component: () => import('@delm/merchant-app/pages/Billing/Route.vue'),
  },
  {
    path: '/general-settings',
    name: AppRoute.GeneralSettings,
    component: () => import('@delm/merchant-app/pages/GeneralSettings/Route.vue'),
  },
  {
    path: '/delivery-areas',
    name: AppRoute.DeliveryAreas,
    component: () => import('@delm/merchant-app/pages/DeliveryAreas/Route.vue'),
  },
  {
    path: '/delivery-areas/new',
    name: AppRoute.DeliveryAreasCreate,
    component: () => import('@delm/merchant-app/pages/DeliveryAreasCreate/Route.vue'),
  },
  {
    path: '/delivery-areas/edit/:id',
    name: AppRoute.DeliveryAreasEdit,
    component: () => import('@delm/merchant-app/pages/DeliveryAreasEdit/Route.vue'),
  },
  {
    path: '/delivery-messages',
    name: AppRoute.DeliveryMessages,
    component: () => import('@delm/merchant-app/pages/DeliveryMessages/Route.vue'),
  },
  {
    path: '/widgets',
    name: AppRoute.Widgets,
    component: () => import('@delm/merchant-app/pages/Widgets/Route.vue'),
  },
  {
    path: '/widgets/new',
    name: AppRoute.WidgetsCreate,
    component: () => import('@delm/merchant-app/pages/WidgetsCreate/Route.vue'),
  },
  {
    path: '/widgets/:uuid',
    name: AppRoute.WidgetsEdit,
    component: () => import('@delm/merchant-app/pages/WidgetsEdit/Route.vue'),
  },
  {
    path: '/products',
    name: AppRoute.Products,
    component: () => import('@delm/merchant-app/pages/Products/Route.vue'),
  },
  {
    path: '/products/edit/:id',
    name: AppRoute.ProductsEdit,
    component: () => import('@delm/merchant-app/pages/ProductsEdit/Route.vue'),
  },
  {
    path: '/holidays',
    name: AppRoute.Holidays,
    component: () => import('@delm/merchant-app/pages/Holidays/Route.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: AppRoute.PageNotFound,
    component: () => import('@delm/merchant-app/pages/PageNotFound.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path !== from.path)
      return savedPosition || { top: 0, left: 0 }
  },
})

export async function preloadAsyncRoutes(router: Router) {
  for (const route of router.getRoutes()) {
    if (!route.components)
      continue

    for (const component of Object.values(route.components)) {
      if (typeof component === 'function') {
        try {
          await (component as () => Promise<any>)()
        }
        catch {
          //
        }
      }
    }
  }
}
