import { createI18n as _createI18n } from 'vue-i18n'
import de from '../lang/de.json'
import en from '../lang/en.json'
import { client as apiClient } from './api'

const supportedLocales = ['en', 'de']

export function setI18nLanguage(locale: string) {
  if (!supportedLocales.includes(locale))
    locale = 'en'

  localStorage.setItem('locale', locale)
  document.querySelector('html')?.setAttribute('lang', locale)
  apiClient.defaults.headers.common['x-app-locale'] = locale
}

export function createI18n() {
  const locale = localStorage.getItem('locale') || window.navigator.language.substring(0, 2).toLowerCase()
  const i18n = _createI18n({
    locale,
    fallbackLocale: 'en',
    messages: { en, de },
  })
  setI18nLanguage(locale)
  return i18n
}
