<script setup lang="ts">
import type { AppSelectOption } from '../types/components'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useBuildVersion } from '../composables/build'
import { setI18nLanguage } from '../i18n'
import AppCountryFlag from './AppCountryFlag.vue'
import AppSelect from './AppSelect.vue'

const { t, locale } = useI18n()
watch(locale, () => {
  setI18nLanguage(locale.value)
})

const languages = computed<AppSelectOption[]>(() => {
  return [
    { title: 'English', value: 'en' },
    { title: 'Deutsch', value: 'de' },
  ]
})

const buildVersion = useBuildVersion()
</script>

<template>
  <div class="select-none text-center text-sm text-gray-500 leading-none">
    <div class="flex flex-wrap items-center justify-center space-x-5">
      <div>
        <AppSelect
          v-model="locale"
          :options="languages"
          size="sm"
        />
      </div>
      <div class="flex items-center justify-center">
        <AppCountryFlag size="20px" code="de" class="mr-2" />
        <div>Made in Germany</div>
      </div>
    </div>

    <div class="flex flex-wrap items-center justify-center pt-5">
      <div class="px-2.5 pb-5">
        <a
          :href="t('link-docs-support')"
          target="_blank"
          class="text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-gray-300"
        >
          {{ t('support') }}
        </a>
      </div>
      <div class="px-2.5 pb-5">
        <a
          href="https://status.delm.io"
          target="_blank"
          class="text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-gray-300"
        >
          Status
        </a>
      </div>
      <div class="px-2.5 pb-5">
        <a
          :href="t('link-imprint')"
          target="_blank"
          class="text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-gray-300"
        >
          {{ t('imprint') }}
        </a>
      </div>
      <div class="px-2.5 pb-5">
        <a
          :href="t('link-privacy-policy')"
          target="_blank"
          class="text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-gray-300"
        >
          {{ t('privacy-policy') }}
        </a>
      </div>
      <div class="px-2.5 pb-5">
        <a
          :href="t('link-terms-of-service')"
          target="_blank"
          class="text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-gray-300"
        >
          {{ t('terms-of-service') }}
        </a>
      </div>
      <div class="px-2.5 pb-5">
        {{ buildVersion }}
      </div>
      <div class="px-2.5 pb-5">
        © {{ new Date().getFullYear() }} delm.io
      </div>
    </div>
  </div>
</template>
