import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import App from './App.vue'

import { createI18n } from './i18n'
import { router } from './router'
import { initSentry } from './sentry'
import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import './main.scss'

const app = createApp(App)
const i18n = createI18n()
const head = createHead({
  titleTemplate: title => (title ? `${title} - Delm` : 'Delm'),
})

if (import.meta.env.VITE_SENTRY_DSN) {
  initSentry({
    app,
    router,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_GIT_COMMIT,
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
      ? Number.parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
      : undefined,
  })
}

app.use(i18n)
app.use(router)
app.use(head)

app.mount('#app')
