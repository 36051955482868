import type { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import type { AppSettings, Country, CustomHoliday, DateCaluclationSettings, DeliveryArea, DeliveryMessages, GeneralSettings, Language, Plan, Product, PublicHoliday, Region, RestOfWorldSettings, Template, User, Widget, WidgetLanguage } from './types/api'
import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { useUser } from './composables/user'
import { AppRoute, router } from './router'
import { getCookie } from './utils'

export const client = applyCaseMiddleware(axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL as string,
  withCredentials: true,
}), { ignoreHeaders: true })

client.interceptors.request.use((config) => {
  const token = decodeURIComponent(getCookie('XSRF-TOKEN'))
  config.headers['X-XSRF-TOKEN'] = token
  return config
})

client.interceptors.response.use((response: AxiosResponse) => {
  return response
}, (error: AxiosError) => {
  if (error.response) {
    const skipLogout = error.response.config.url?.includes('auth') || false
    const logoutCodes = [401, 419]

    if (logoutCodes.includes(error.response.status) && !skipLogout) {
      const { setUser } = useUser()
      setUser(undefined)
      router.push({ name: AppRoute.Login })
      return
    }
  }

  return Promise.reject(error)
})

interface PaginatedResponse<T = any> {
  data: T
  links: {
    first: string
    last: string
    prev?: number
    next?: number
  }
  meta: {
    currentPage: number
    from: number
    lastPage: number
    path: string
    perPage: number
    to: number
    total: number
  }
}

interface LoginResponse {
  authUrl?: string
  user?: User
}

export function login(options: any): AxiosPromise<LoginResponse> {
  return client.post('/auth/login', options)
}

export function adminLogin(shop: string, password: string): AxiosPromise<User> {
  return client.post('/auth/admin', { shop, password })
}

export function checkAuth(): AxiosPromise<LoginResponse> {
  return client.post('/auth/check')
}

export function logout(): AxiosPromise {
  return client.post('/auth/logout')
}

export function csrfCookie(): AxiosPromise {
  return client.get('/auth/csrf-cookie')
}

export function getUser(): AxiosPromise<User> {
  return client.get('/user')
}

interface GetBillingResponse {
  user: User
  plan: Plan
}

export function getBilling(): AxiosPromise<GetBillingResponse> {
  return client.get('/billing')
}

/**
 * Returned string is the Shopify confirmation URL.
 */
export function subscribeDefaultPlan(): AxiosPromise<string> {
  return client.post('/billing/subscribe')
}

export function cancelActiveSubscription(): AxiosPromise<User> {
  return client.post('/billing/cancel')
}

interface GeneralSettingsResponse {
  appSettings: AppSettings
  dateCalculationSettings: DateCaluclationSettings
  restOfWorldSettings: RestOfWorldSettings
  timezones: string[]
}

export function enableApp(): AxiosPromise<GeneralSettingsResponse> {
  return client.put('/general-settings/enable-app')
}

export function disableApp(): AxiosPromise<GeneralSettingsResponse> {
  return client.put('/general-settings/disable-app')
}

export function getGeneralSettings(): AxiosPromise<GeneralSettingsResponse> {
  return client.get('/general-settings')
}

export function updateGeneralSettings(generalSettings: GeneralSettings): AxiosPromise<GeneralSettingsResponse> {
  return client.put('/general-settings', generalSettings)
}

export function getDeliveryAreas(): AxiosPromise<DeliveryArea[]> {
  return client.get('/delivery-areas')
}

interface DeliveryAreaResponse {
  deliveryArea: DeliveryArea
  allCountries: Country[]
  disabledCountries: Country[]
  disabledRegions: Region[]
}

export function getCreateDeliveryAreaDefaults(): AxiosPromise<DeliveryAreaResponse> {
  return client.get('/delivery-areas/create')
}

export function createDeliveryArea(deliveryArea: DeliveryArea): AxiosPromise<DeliveryAreaResponse> {
  return client.post('/delivery-areas/create', deliveryArea)
}

export function getDeliveryArea(id: number | string): AxiosPromise<DeliveryAreaResponse> {
  return client.get(`/delivery-areas/${id}`)
}

export function updateDeliveryArea(deliveryArea: DeliveryArea): AxiosPromise<DeliveryAreaResponse> {
  return client.put(`/delivery-areas/${deliveryArea.id}`, deliveryArea)
}

export function deleteDeliveryArea(deliveryArea: DeliveryArea): AxiosPromise<void> {
  return client.delete(`/delivery-areas/${deliveryArea.id}`)
}

interface GetProductsResponse extends PaginatedResponse<Product[]> {
  query: string
}

export function getProducts(query?: string, page?: number): AxiosPromise<GetProductsResponse> {
  return client.get('/products', { params: { query, page } })
}

interface GetProductResponse {
  product: Product
  deliveryAreas: DeliveryArea[]
}

export function getProduct(id: number | string): AxiosPromise<GetProductResponse> {
  return client.get(`/products/${id}`)
}

export function updateProduct(product: Product): AxiosPromise<GetProductResponse> {
  return client.put(`/products/${product.id}`, product)
}

interface DeliveryMessagesResponse {
  appSettings: AppSettings
  templates: Template[]
  languages: Language[]
  positions: string[]
}

export function getDeliveryMessages(): AxiosPromise<DeliveryMessagesResponse> {
  return client.get('/delivery-messages')
}

export function updateDeliveryMessages(settings: DeliveryMessages): AxiosPromise<DeliveryMessagesResponse> {
  return client.put('/delivery-messages', settings)
}

export function disableDeliveryMessages(): AxiosPromise<DeliveryMessagesResponse> {
  return client.put('/delivery-messages/disable')
}

export function enableDeliveryMessages(): AxiosPromise<DeliveryMessagesResponse> {
  return client.put('/delivery-messages/enable')
}

export function getCustomHolidays(): AxiosPromise<CustomHoliday[]> {
  return client.get('/custom-holidays')
}

export function createCustomHoliday(customHoliday: CustomHoliday): AxiosPromise<CustomHoliday> {
  return client.post('/custom-holidays', customHoliday)
}

export function deleteCustomHoliday(customHoliday: CustomHoliday): AxiosPromise<void> {
  return client.delete(`/custom-holidays/${customHoliday.id}`)
}

export function getUpComingPublicHolidays(countryId: number, lastDate?: string): AxiosPromise<PublicHoliday[]> {
  return client.get('/upcoming-public-holidays', {
    params: {
      country_id: countryId,
      last_date: lastDate,
    },
  })
}

export function getAllCountries(): AxiosPromise<Country[]> {
  return client.get('/all-countries')
}

export function getAllLanguages(): AxiosPromise<Language[]> {
  return client.get('/all-languages')
}

export function getWidgets(page?: number): AxiosPromise<PaginatedResponse<Widget[]>> {
  return client.get('/widgets', { params: { page } })
}

export function createWidget(widget: Widget): AxiosPromise<Widget> {
  return client.post('/widgets', widget)
}

export function getWidget(uuid: string): AxiosPromise<Widget> {
  return client.get(`/widgets/${uuid}`)
}

export function updateWidget(widget: Widget): AxiosPromise<Widget> {
  return client.put(`/widgets/${widget.uuid}`, widget)
}

export function deleteWidget(widget: Widget): AxiosPromise<Widget> {
  return client.delete(`/widgets/${widget.uuid}`)
}

export function promoteWidget(widget: Widget): AxiosPromise<Widget> {
  return client.put(`/widgets/${widget.uuid}/promote`, widget)
}

export function demoteWidget(widget: Widget): AxiosPromise<Widget> {
  return client.put(`/widgets/${widget.uuid}/demote`, widget)
}

export function duplicateWidget(widget: Widget): AxiosPromise<Widget> {
  return client.post(`/widgets/${widget.uuid}/duplicate`)
}

export function getEmptyWidgetLanguage(locale: string): AxiosPromise<WidgetLanguage> {
  return client.get(`/empty-widget-translation/${locale}`)
}
