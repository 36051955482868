<script setup lang="ts">
import { mdiAlert, mdiChevronRight } from '@mdi/js'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { AppRoute } from '../router'
import AppBtn from './AppBtn.vue'
import AppIcon from './AppIcon.vue'

const router = useRouter()
const { t } = useI18n()
</script>

<template>
  <div class="flex flex-wrap items-center justify-between border border-green-200 rounded-lg bg-green-50 px-6 py-3 lg:flex-nowrap">
    <div class="flex items-center space-x-3">
      <AppIcon :icon="mdiAlert" class="text-green-700" size="24px" />
      <span class="text-lg text-green-800 font-medium">{{ t('subscription-required-info') }}</span>
    </div>
    <div class="mt-3 w-full lg:mt-0 lg:w-auto">
      <AppBtn
        color="green"
        :icon-after="mdiChevronRight"
        @click="router.push({ name: AppRoute.Billing })"
      >
        {{ t('continue') }}
      </AppBtn>
    </div>
  </div>
</template>
