<script setup lang="ts">
import { mdiBeach, mdiCardText, mdiCog, mdiCurrencyUsd, mdiEarth, mdiHome, mdiLifebuoy, mdiSchool, mdiTag } from '@mdi/js'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLayout } from '../composables/layout'
import { AppRoute } from '../router'
import AppSidebarItem from './AppSidebarItem.vue'

const props = defineProps<{
  currentRoute?: AppRoute
}>()

const { t } = useI18n()
const router = useRouter()
const { sidebarWidth, isMobile, hideSidebar, sidebarVisible, headerOffset } = useLayout()

const showBackdrop = computed(() => {
  return isMobile.value && sidebarVisible.value
})

watch(() => router.currentRoute.value.path, () => {
  if (isMobile.value)
    hideSidebar()
})

interface Item {
  name: string
  icon: string
  appRoute: AppRoute
}

const items = computed<Item[]>(() => {
  return [
    {
      name: 'Home',
      icon: mdiHome,
      appRoute: AppRoute.Home,
    },
    {
      name: t('general-settings'),
      icon: mdiCog,
      appRoute: AppRoute.GeneralSettings,
    },
    {
      name: t('widgets'),
      icon: mdiCardText,
      appRoute: AppRoute.Widgets,
    },
    {
      name: t('delivery-areas'),
      icon: mdiEarth,
      appRoute: AppRoute.DeliveryAreas,
    },
    {
      name: t('products'),
      icon: mdiTag,
      appRoute: AppRoute.Products,
    },
    {
      name: t('holidays'),
      icon: mdiBeach,
      appRoute: AppRoute.Holidays,
    },
  ]
})
</script>

<template>
  <aside
    class="fixed bottom-0 left-0 top-0 flex flex-col justify-between overflow-auto border-r bg-white"
    :class="{ 'z-20': isMobile }"
    :style="{
      width: `${sidebarWidth}px`,
      minWidth: `${sidebarWidth}px`,
      maxWidth: `${sidebarWidth}px`,
      marginTop: `${headerOffset}px`,
    }"
  >
    <div>
      <ul class="pt-5 space-y-2px">
        <li v-for="(item, index) in items" :key="`item-${index}`">
          <AppSidebarItem
            :app-route="item.appRoute"
            :current-app-route="props.currentRoute"
            :icon="item.icon"
            @click.prevent="router.push({ name: item.appRoute })"
          >
            {{ item.name }}
          </AppSidebarItem>
        </li>
      </ul>
    </div>

    <ul class="pb-5 space-y-2px">
      <li>
        <AppSidebarItem
          :app-route="AppRoute.Billing"
          :current-app-route="props.currentRoute"
          :icon="mdiCurrencyUsd"
          @click.prevent="router.push({ name: AppRoute.Billing })"
        >
          {{ t('billing') }}
        </AppSidebarItem>
      </li>
      <li>
        <AppSidebarItem
          :href="t('link-docs')"
          :icon="mdiSchool"
        >
          {{ t('docs') }}
        </AppSidebarItem>
      </li>
      <li>
        <AppSidebarItem
          :href="t('link-docs-support')"
          :icon="mdiLifebuoy"
        >
          {{ t('support') }}
        </AppSidebarItem>
      </li>
    </ul>
  </aside>
  <div
    v-if="showBackdrop"
    class="fixed inset-0 z-10 h-full w-full bg-gray-900 bg-opacity-50 backdrop-blur-2px backdrop-filter"
    @click="hideSidebar()"
  />
</template>
