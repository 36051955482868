<script setup lang="ts">
import logoInverted from '../images/logo-inverted.svg'
import logo from '../images/logo.svg'

const props = defineProps<{
  inverted?: boolean
}>()
</script>

<template>
  <div class="select-none !no-underline">
    <img :src="props.inverted ? logoInverted : logo" alt="Delm" class="h-20px">
  </div>
</template>
