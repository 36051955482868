<script setup lang="ts">
import { mdiAccount, mdiCurrencyUsd, mdiLifebuoy, mdiLogout, mdiMenu, mdiSchool } from '@mdi/js'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLayout } from '../composables/layout'
import { useUser } from '../composables/user'
import { AppRoute } from '../router'
import { resolveRouteHref } from '../utils'
import AppDropdown from './AppDropdown.vue'
import AppDropdownItem from './AppDropdownItem.vue'
import AppIcon from './AppIcon.vue'
import AppLogo from './AppLogo.vue'

const emit = defineEmits<{
  (event: 'logout'): void
}>()

const { t } = useI18n()
const router = useRouter()
const { headerHeight, toggleSidebar, isMobile } = useLayout()
const { user } = useUser()
</script>

<template>
  <header
    class="fixed inset-x-0 top-0 z-10 flex items-center justify-between bg-white px-4 shadow"
    :style="{
      minHeight: `${headerHeight}px`,
      height: `${headerHeight}px`,
      maxHeight: `${headerHeight}px`,
    }"
  >
    <div class="flex items-center" :style="{ height: `${headerHeight}px` }">
      <AppIcon
        v-if="isMobile"
        :icon="mdiMenu"
        size="32px"
        class="cursor-pointer"
        @click="toggleSidebar()"
      />
      <a
        v-else
        href="/"
        class="flex"
        @click.prevent="router.push({ name: AppRoute.Home })"
      >
        <AppLogo />
      </a>
    </div>
    <AppDropdown>
      <template #trigger>
        <div class="group max-w-400px flex cursor-pointer select-none items-center rounded-lg py-1.5">
          <div class="h-24px w-24px flex items-center justify-center rounded-full bg-primary-100 p-1 text-white leading-none">
            <AppIcon size="16px" class="text-primary" :icon="mdiAccount" />
          </div>
          <div class="ml-2.5 max-w-180px truncate font-medium group-hover:text-primary">
            {{ user!.shopDomain }}
          </div>
        </div>
      </template>
      <template #content>
        <AppDropdownItem
          :icon="mdiCurrencyUsd"
          :href="resolveRouteHref({ name: AppRoute.Billing })"
          @click="router.push({ name: AppRoute.Billing })"
        >
          {{ t('billing') }}
        </AppDropdownItem>
        <AppDropdownItem
          :icon="mdiSchool"
          :href="t('link-docs')"
          external
        >
          {{ t('docs') }}
        </AppDropdownItem>
        <AppDropdownItem
          :icon="mdiLifebuoy"
          :href="t('link-docs-support')"
          external
        >
          {{ t('support') }}
        </AppDropdownItem>
        <AppDropdownItem
          :icon="mdiLogout"
          @click="emit('logout')"
        >
          {{ t('logout') }}
        </AppDropdownItem>
      </template>
    </AppDropdown>
  </header>
</template>
